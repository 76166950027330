import styled from 'styled-components';

export const MobileMapWrapper = styled.div`
  .swiper-container {
    padding: 20px 80px 20px 20px;
  }
  .page-switch {
    align-items: center;
    border-radius: 6px;
    box-shadow: 2px 2px 20px 5px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 30px;
    padding: 20px 15px;
    width: 80%;
    height: 80%;
    margin: auto;
    .icon {
      align-items: center;
      background: ${props => props.theme.color.primaryColor};
      border-radius: 35px;
      color: white;
      display: flex;
      font-size: 60px;
      font-weight: bold;
      height: 70px;
      justify-content: center;
      width: 70px;
    }
  }
`;
