import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  .results-header {
    grid-template-areas: "top" "bottom";
    display: flex; 
    flex-direction: row-reverse;
    align-items: top;
    
    .top,
    .bottom {
      display: flex;
      align-items: center;
    }

    .top {
      grid-area: top;
      justify-content: space-between;
    }

    .bottom {
      grid-area: bottom;
      cursor: pointer;
    }

    .nb-results {
      color: #FE7418;
      font-size: ${props => `${17*props.theme.font.primaryFont.scaling/100}px`};
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      width: 90px;
      white-space: nowrap;
    }

    .modify-search {
      color: black;
      text-decoration: underline;
      font-size: ${props => `${13*props.theme.font.primaryFont.scaling/100}px`};
    }

    .bouton-other-filters{
      background: white;
      border: 1px solid #707070;
      border-radius: 20px;
      box-shadow: 2px 5px 8px #00000029;
      height: 46px;
      margin-right:12px;
      padding: 12px 18px;
      &:hover{
        background: ${props => props.theme.color.primaryColor};
      }
    }

    .filters-drop-down {
      width: 100%;
      display: flex;
      justify-content: ${props => props.isMobile ? 'space-between' : 'flex-start'};
      align-items: center;
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      border: 1px solid hsl(0, 0%, 90%);
      border-radius: 6px;
      padding: 10px 5px;

      .icon,
      .active {
        color: ${props => props.theme.color.primaryColor};
      }

      .icon,
      .filters-drop-down-title,
      .active {
        margin-right: ${props => (props.isMobile ? '0' : '10px')};
      }

      .arrow {
        margin-left: ${props => (props.isMobile ? '0' : 'auto')};
      }
    }

    .show-map {
      background: ${props => props.theme.color.primaryColor};
      color: white;
      padding: 8px;
      border-radius: 6px;
      font-size: 33px;
      line-height: 1;
      display: flex;
      margin-left: 10px;
    }
  }

  .filters-block {
    display: flex;
    align-items: center;
    border: ${props => `1px solid ${props.theme.color.primaryColor}`};
    border-radius: 18px;
    padding: 5px 10px;
    margin-top: ${props => props.isFirstRowVisible === false && props.isSecondRowVisible === false ? '24px' : ''};
    margin-left: ${props => (props.isMobile ? '' : '40px')};
    .filters {
      display: -webkit-box;
      overflow-x: auto;
      margin-right: 30px;
      &::-webkit-scrollbar {
        display: none;
      }
      .filter {
        display: flex;
        align-items: center;
        background: ${props => props.theme.color.tertiaryColor};
        border-radius: 15px;
        padding: 3px 5px 3px 8px;
        font-size: ${props => `${13*props.theme.font.primaryFont.scaling/100}px`};
        margin-left: 5px;
        &.edit-filters {
          position: absolute;
          right: 25px;
          width: 25px;
          height: 25px;
          justify-content: center;
          font-size: 15px;
          padding: 3px 8px;
          &:hover {
            cursor: pointer;
          }
        }
        .delete-tag {
          margin-left: 3px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
`;
