import React, { useContext, useState, useEffect } from 'react';
import {ThemeContext, WhiteLabelContext} from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import { ProfileWrapper } from './autocomplete-profile.styles';

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const AutocompleteProfile = props => {
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const theme = useContext(ThemeContext).state;
  let profiles = props.profiles;
  let defaultProfile = profiles[0];
  const [memValue, setMemValue] = useState(defaultProfile.name);
  const [value, setValue] = useState(defaultProfile.name);
  const [profilesDisplayed, setProfilesDisplayed] = useState(profiles);
  const [blur, setBlur] = useState(false);

  useEffect(() => {
    setProfilesDisplayed([...profiles]);
    defaultProfile = props.defaultProfile? profiles.find(profile => profile.id === props.defaultProfile) : profiles[0];
    setMemValue(defaultProfile.name);
    setValue(defaultProfile.name);
  }, []);

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  useEffect(() => {
    if (blur && value === '') {
      setValue(memValue);
      setBlur(false);
    }
  }, [blur]);

  const getSuggestions = value => {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return profiles;
    }

    return profiles.filter(profile => profile.name.toLowerCase().includes(value.toLowerCase()));
  };
  const getSuggestionValue = profile=> profile.name;
  const renderSuggestion = profile => profile.name;

  const onSuggestionsFetchRequested = ({ value }) => {
    setProfilesDisplayed(getSuggestions(value));
    setBlur(false);
  };
  const onSuggestionsClearRequested = () => {
    setProfilesDisplayed(profiles);
  };
  const onSuggestionSelected = (event, { suggestion }) => {
    props.onSelect({id: suggestion.id});
  };
  const shouldRenderSuggestions = (value) => {
    return true;
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    setMemValue(newValue);
  };
  const onBlur = () => {
    setBlur(true);
  };
  const handleFocus = () => {
    const closeTo = document.getElementById('a-proximite-de');
    if (isMobile && closeTo) {
      window.scrollTo({
        top: closeTo.offsetTop,
        behavior: 'smooth',
      });
    }
    setValue('');
    window.setTimeout(function() {
      setProfilesDisplayed(profiles);
    }, 50);
  }
  const inputProps = {
    value,
    onChange: onChange,
    onFocus: handleFocus,
    onBlur: onBlur
  };
  return (
    <ProfileWrapper theme={theme} isMobile={isMobile}>
        <i className="icon adn adn-baby-face-outline" />  
      <Autosuggest
        suggestions={profilesDisplayed}
        focusInputOnSuggestionClick={false}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={shouldRenderSuggestions}
        inputProps={inputProps}
      />
    </ProfileWrapper>
  )
};

AutocompleteProfile.propTypes = {
  profiles: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  defaultProfile: PropTypes.number,
}

export default AutocompleteProfile;
