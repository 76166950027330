import React, { useContext, useState, useEffect } from 'react';
import {ThemeContext, WhiteLabelContext} from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import { LocationWrapper } from './autocomplete-location.styles';

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const AutocompleteLocation = props => {
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const theme = useContext(ThemeContext).state;
  let locations = props.locations;
  let defaultLocation = locations[0];
  const [memValue, setMemValue] = useState(defaultLocation.name);
  const [value, setValue] = useState(defaultLocation.name);
  const [locationsDisplayed, setLocationsDisplayed] = useState(locations);
  const [locationId, setLocationId] = useState(defaultLocation.id);
  const [blur, setBlur] = useState(false);

  useEffect(() => {
    if (props.geolocation.isGeolocated && props.isInZone) {
      locations.unshift({
        id: 0,
        name: whiteLabel.textualContent.page.search.aroundMe,
        longitude: props.geolocation.coordinates.longitude,
        latitude: props.geolocation.coordinates.latitude,
      });
    } else if (!props.isInZone) {
      let firstLocation = null;
      let firstLocationIndex = null;
      for (const [index, location] of locations.entries()) {
        if (location.id === props.whiteLabelCity) {
          firstLocation = location;
          firstLocationIndex = index;
        }
      }
      locations.splice(firstLocationIndex, 1);
      locations.unshift(firstLocation);
    }
    setLocationsDisplayed([...locations]);
    defaultLocation = props.defaultLocation ? locations.find(location => location.id === props.defaultLocation) : locations[0];
    setValue(defaultLocation.name);
    setValue(defaultLocation.name);


    //props.onSelect({id: defaultLocation.id, longitude: defaultLocation.longitude, latitude: defaultLocation.latitude});
  }, [props.geolocation.isGeolocated, props.isInzone]);

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  useEffect(() => {
    if (blur && value === '') {
      setValue(memValue);
      setBlur(false);
    }
  }, [blur]);

  const getSuggestions = value => {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return locations;
    }

    return locations.filter(location => location.name.toLowerCase().includes(value.toLowerCase()));
  };

  const getSuggestionValue = location => location.name;
  const renderSuggestion = location => location.name;

  const onSuggestionsFetchRequested = ({ value }) => {
    setBlur(false);
    setLocationsDisplayed(getSuggestions(value));
  };
  const onSuggestionsClearRequested = () => {
    setLocationsDisplayed(locations);
  };
  const onSuggestionSelected = (event, { suggestion }) => {
    props.onSelect({id: suggestion.id, longitude: suggestion.longitude, latitude: suggestion.latitude});
  };
  const shouldRenderSuggestions = (value) => {
    return true;
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    setMemValue(newValue);
  };
  const onBlur = () => {
    setBlur(true);
  };
  const handleFocus = () => {
    const closeTo = document.getElementById('a-proximite-de');
    if (isMobile && closeTo) {
      window.scrollTo({
        top: closeTo.offsetTop,
        behavior: 'smooth',
      });
    }
    setValue('');
    window.setTimeout(function() {
      setLocationsDisplayed(locations);
    }, 50);
  }
  const inputProps = {
    value,
    onChange: onChange,
    onFocus: handleFocus,
    onBlur: onBlur
  };
  return (
    <LocationWrapper theme={theme} isMobile={isMobile}>
      <i className="icon adn adn-map-marker"></i>
      <Autosuggest
        suggestions={locationsDisplayed}
        focusInputOnSuggestionClick={false}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={shouldRenderSuggestions}
        inputProps={inputProps}
      />
    </LocationWrapper>
  )
};

AutocompleteLocation.propTypes = {
  locations: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  geolocation: PropTypes.object.isRequired,
  isInZone: PropTypes.bool.isRequired,
  whiteLabelCity: PropTypes.number.isRequired,
  defaultLocation: PropTypes.number,
}

export default AutocompleteLocation;
