import styled from 'styled-components';

const LocationWrapper = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  border-radius:20px;

  .icon {
    margin-right: -30px;
    margin-top: 10px;
    z-index: 1;
    align-items: flex-start;
    font-size:24px;
    &.adn-magnify{
      margin-left:-30px;
    }
  }

  .react-autosuggest__container {
    width: 100%;
    position: relative;
  }

  .react-autosuggest__input {
    width:240px;
    max-width:240px;
    padding-right:20px;
    padding-top: 12px;
    padding-bottom:10px;
    padding-left:40px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 15px;
    background: white;
    border: 1px dashed #707070;
    box-shadow: 2px 5px 8px #00000029;
    border-radius: 20px;
    height: 46px;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {

  }

  .react-autosuggest__suggestions-container {
    display: flex;
  }

  .react-autosuggest__suggestions-container--open {
    display: flex;
    margin-top: -1px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 15px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px dashed #707070;
    box-shadow: 2px 5px 8px #00000029;
    z-index: 100;
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 24px);
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 12px;
    width: 100%;
  }

  .react-autosuggest__suggestion--highlighted {
    width: 100%;
    background-color: #ddd;
  }
`;

export {LocationWrapper};
