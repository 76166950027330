import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import DesireSlide from 'ToolboxComponents/commons/cards/desire-product-card/desire-product-card';

const SliderDesires = props => {
  const { primaryColor } = useContext(ThemeContext).state.color;
  const [desireSelected, setDesireSelected] = useState(props.defaultDesire);

  let defaultDesire = null;
  if (props.defaultDesire) {
    defaultDesire = props.desires.findIndex(
      desire => desire.id === props.defaultDesire,
    );
  }
  
  return (
    <Slider
      desktop={{
        slidesPerView: 7,
        spaceBetween: 10,
        arrows: true,
        arrowsMiddle: true,
        arrowsDisabledHide: true,
        slidesPerGroup: 5,
        slidesPadding: 5,
      }}
      mobile={{
        slidesPerView: 2.25,
        slidesPerGroup: 2,
        dynamicBullets: false,
        dots: false,
      }}
      dotsColorActive={primaryColor}
      slideTo={defaultDesire}
    >
      {props.desires.map((desire, index) => (
        <DesireSlide
          key={index}
          index={index}
          desire={desire}
          setDesireSelected={desire => {setDesireSelected(desire); props.onSelect(desire)}}
          desireSelected={desireSelected}
        />
      ))}
    </Slider>
  );
};

SliderDesires.propTypes = {
  desires: PropTypes.array.isRequired,
  defaultDesire: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};

export default SliderDesires;
