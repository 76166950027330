import styled from 'styled-components';

export const FiltersPanelWrapper = styled.div`
  .filters-panel {
    .filters-wrapper {
      margin-top: ${props => (props.isMobile ? '0' : '10px')};
      margin-bottom: ${props => (props.isMobile ? '' : '20px')};
      width: 100%;
      min-height: ${props => (props.isMobile ? '' : 'auto')};
      position: ${props => (props.isMobile ? '' : 'absolute')};
      top: ${props => (props.isMobile ? '' : '150px')};
    }

    .title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .hashtag {
      font-size: 17px;
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
    }

    .filters-close {
      line-height: 1;
      font-size: 40px;
      cursor: pointer;
    }

    .filters-body {
      display: flex;

      .secret {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: auto;
        box-shadow: 2px 5px 8px #00000029;
        border: 2px solid #3A3E42;
        border-radius: 20px;
        max-width: 150px;
        &:hover{
          background: ${props => props.theme.color.primaryColor};
          border-color:transparent;
        }
        .bouton-secret-only{
          width:100%;
        }
        .adn-star-circle {
          font-size: 60px;
        }
        .text {
          text-align: center;
        }
        .secrets-status {
          background: ${props => props.isOnlySecrets === true ? props.theme.color.primaryColor : props.theme.color.quaternaryColor};
          height: 30px;
          width: 70px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: ${props => props.isOnlySecrets === true ? 'flex-end' : 'flex-start'};
          cursor: pointer;
          .toggle-pill {
            background: #ffffff;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            margin: 0 4px;
          }
        }

      }

      .separation {
        display: flex;
        width: 100%;
        max-width: 600px;
        align-items: center;
        margin: auto;
        margin-bottom: 20px;
        color: ${props => props.theme.color.primaryColor};
        hr {
          border: 2px solid ${props => props.theme.color.primaryColor};
          width: 100%;
        }
        .text {
          min-width: 200px;
          text-align: center;
        }
      }
      .tags {
        overflow-y:auto;
        max-height:200px;
        white-space:break-spaces;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .tag {
          display: flex;
          background: white;
          justify-content: center;
          align-items: center;
          padding: 5px 5px 5px 10px;
          border-radius: 50px;
          margin: 5px;
          cursor: pointer;
          font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
          font-size: ${props => `${14*props.theme.font.primaryFont.scaling/100}px`};
          user-select: none;
          box-shadow: 0 0 0 2px rgba(0,0,0,0.7) inset;

          &.active {
            // box-shadow: 0 0 0 2px ${props => props.theme.color.primaryColor} inset;
            box-shadow: none;
            background: ${props => props.theme.color.primaryColor};

            .title {
              color: white;
            }

            .number {
              box-shadow: 0 0 0 2px white inset;
            }
          }

          &.inactive {
            background: gray;
            box-shadow: none;
          }
        }
      }


      .title {
        margin-right: 5px;
      }

      .number {
        background: ${props => props.theme.color.primaryColor};
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        flex-shrink: 0;
      }
    }

    .filters-display-results {
      position: ${props => (props.isMobile ? '' : 'relative')};
      bottom: 0;
      left: 0;
      right: 0;
      padding: ${props => (props.isMobile ? '20px' : '20px 20px 0')};
      display: flex;
      justify-content: center;
      background: ${props => (props.isMobile ? '' : 'white')};

      .results-btn {
        background: ${props => props.theme.color.secondaryColor};
        border: 0;
        padding: 10px;
        color: white;
        border-radius: 22px;
        width: 280px;
        font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)};
      }
    }
  }
  @media (min-width: 767px) {
    .filters-panel{
      z-index: 5;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: ${props => (props.isMobile ? 'block' : 'flex')};
      justify-content: center;
      align-items: center;
      padding: ${props => (props.isMobile ? '0' : '20px')};
      .filters-wrapper {
        box-shadow: 5px 4px 26px #00000029;
        background: white;
        padding: 20px;
        padding-top: 10px;
      }
      .filters-body{
        flex-direction: row-reverse;
        margin-top: 30px;
      }
    }
    }
    
`;
