import React, { useState, useEffect, useContext } from 'react';
import {ThemeContext} from "ToolboxUtils/web/context/context";
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import PropTypes from 'prop-types';
import { DesireWrapper } from './desire-product-card.styles';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';

const DesireSlider = props => {
  const theme = useContext(ThemeContext).state;

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  return (
    <DesireWrapper theme={theme} isMobile={isMobile}>
      <button type="button"
        onClick={() => {props.setDesireSelected(props.desire.id); props.onClick && props.onClick()}}
        className={'btn-search' + (props.desire.id === props.desireSelected ? ' active' : '')}
      >
        <React.Fragment>
          <ImageResize
            src={props.desire.picture}
          />
          {/* <img src={process.env.CONFIG.DESIRES_URL + props.desire.picture} alt=""  /> */}
          <span className={isMobile ? "search-form-label search-form-label-mobile": "search-form-label"}>{props.desire.name}</span>
        </React.Fragment>
      </button>
    </DesireWrapper>
  );
};

DesireSlider.propTypes = {
  desire: PropTypes.object.isRequired,
};

export default DesireSlider;
