import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/components/is-mobile-view';
import logoBifurcation from 'ToolboxAssets/img/logo-bifurcations-petit.png';
import logoTorch from 'ToolboxAssets/img/torch.png';

export const CardWrapper = styled.div`
  background: ${props => props.highlight ? '#F0DF6B' : 'white'};
  margin: 20px 0;
  font-size: ${props => `${14*props.theme.font.primaryFont.scaling/100}px`};
  transition: all 0.1s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transform: ${props => props.isSelected === true ? 'scale(1.025)' : 'scale(1)'};
  position: relative;
  & > div {
    position: relative;
  }


  .highlight-block {
    position:absolute;
    top:-28px;
    right:0;
    display:block;
    background:#F0DF6B;
    width:176px;
    height:28px;
    z-index: 1000;
    border-radius: 10px 10px 0 0;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: bold;
  }
  .highlight-block i{
    margin-right:3px;
    font-size:16px;
  }

  .header {
    position: relative;
    z-index: 2;
    height: 210px;

    .image {
      width: 100%;
      height: 210px;
      object-fit: cover;
    }
  }
  .macaron-biennale{
    &:after{
      content:'';
      display:block;
      background: url(${logoBifurcation}) no-repeat;
      width: 120px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -60px;
  }
  }
  .macaron-torch{
    &:after{
      content:'';
      display:block;
      background: url(${logoTorch}) no-repeat white;
      background-size: contain;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 10;
    }
  }

  .content {
    padding: 20px 20px 10px;

    .title, .city, .description, .nb-secrets {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .title, .city {
      -webkit-line-clamp: 1;
    }

    .title {
      font-size: ${props => `${17*props.theme.font.primaryFont.scaling/100}px`};
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
    }

    .nb-secrets {
      position: absolute;
      z-index:100;
      right: 10px;
      top: 198px;
      background-color: white;
      border-radius: 12px;
      display: block;
      height: 26px;
      padding: 3px 10px 4px 10px;
      border: 1px solid #DDDDDD;
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      margin-bottom: 5px;
    }

    .description {
      -webkit-line-clamp: 2;
      line-height: 18px;
      min-height: 36px;
      max-height:36px;
    }
  }

  .footer {
    padding: 0 20px 10px;
    display: flex;
    overflow: hidden;
    margin-right: -10px;

    .distance, .business-hours {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 5px;
        font-size: 20px;
        color: black;
        &.adn-location-arrow {
          font-size: 14px;
        }
      }
    }

    .distance {
      margin-right: 14px;
      white-space: nowrap;      
    }

    .business-hours {
      color: green;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;    
    }
  }

  .is-on {
    color: green;
  }

  .is-info {
    color: blue;
  }

  .is-off {
    color: blue;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    margin: 20px 0;
    box-shadow: ${props =>
      props.isSelected === true
        ? '2px 2px 20px 5px rgba(0, 0, 0, 0.15)'
        : '2px 2px 20px 5px rgba(0, 0, 0, 0.1)'};
      .header {
        height: 233px;
        width:300px;
          .image {
            height: 100%;
            width:300px;
          }
        } 
      }
  }
`;
