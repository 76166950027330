//put nearest product on first place
function moveNearestProductOnFirstPlace(products) {
  let minDistance = products[0].distance;
  let minDistancePosition = 0;
  for (let i = 1; i < products.length; i++) {
    if (products[i].distance < minDistance) {
      minDistance = products[i].distance;
      minDistancePosition = i;
    }
  }
  const sortedProducts = [products[minDistancePosition]];
  // instead of filter to maximize performance (no copy)
  for (let i = 0; i < products.length; i++) {
    if (i !== minDistancePosition) {
      sortedProducts.push(products[i]);
    }
  }
  return sortedProducts;
}



// put nearest product and most whispered product on first places
export default function sortProducts(allProducts, isGeolocated, isInZone, locationId) {
  if (allProducts.length === 0) {
    return {hasOneHighlightProductOnly: false, sortedProducts: []};
  }
  const products = [];
  const outsideProducts = [];
  for (let i = 0; i < allProducts.length; i++) {
    const product = allProducts[i];
    if (product.cityId === locationId) {
      products.push(product);
    } else {
      outsideProducts.push(product);
    }
  }

  if (products.length === 0) {
    if (!isGeolocated || !isInZone) {
      return {hasNoHighlightProduct: true, sortedProducts: outsideProducts};
    } else {
      return {hasOneHighlightProductOnly: false, sortedProducts: moveNearestProductOnFirstPlace(outsideProducts)};
    }
  }
  if (!isGeolocated || !isInZone || locationId !== 0) {
    let maxSecretCount = products[0].secretCount;
    let maxSecretCountPosition = 0;
    for (let i = 1; i < products.length; i++) {
      if (products[i].secretCount > maxSecretCount) {
        maxSecretCount = products[i].secretCount;
        maxSecretCountPosition = i;
      }
    }
    const sortedProducts = [products[maxSecretCountPosition]];
    // instead of filter to maximize performance (no copy)
    for (let i = 0; i < products.length; i++) {
      if (i !== maxSecretCountPosition) {
        sortedProducts.push(products[i]);
      }
    }
    sortedProducts.push(...outsideProducts);
    return {hasOneHighlightProductOnly: true, sortedProducts, isSecretHighlightOnly: true, maxSecretCount};
  }
  let minDistance = products[0].distance;
  let maxSecretCount = products[0].secretCount;
  let minDistancePosition = 0;
  let maxSecretCountPosition = 0;
  for (let i = 1; i < products.length; i++) {
    if (products[i].distance < minDistance) {
      minDistance = products[i].distance;
      minDistancePosition = i;
    }
    if (products[i].secretCount > maxSecretCount) {
      maxSecretCount = products[i].secretCount;
      maxSecretCountPosition = i;
    }
  }
  const hasOneHighlightProductOnly = minDistancePosition === maxSecretCountPosition || !maxSecretCount;
  const sortedProducts = hasOneHighlightProductOnly ? 
    [products[minDistancePosition]] : 
    [products[minDistancePosition], products[maxSecretCountPosition]];
  // instead of filter to maximize performance (no copy)
  for (let i = 0; i < products.length; i++) {
    if (!(i === minDistancePosition || (!hasOneHighlightProductOnly && i === maxSecretCountPosition))) {
      sortedProducts.push(products[i]);
    }
  }
  sortedProducts.push(...outsideProducts);
  return {hasOneHighlightProductOnly, sortedProducts, isSecretHighlightOnly: false, maxSecretCount};
}
