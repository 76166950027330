import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import {
  ThemeContext
} from 'ToolboxUtils/web/context/context';
import { WebappContext,LanguageContext,WhiteLabelContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import SliderDesires from 'ToolboxComponents/commons/sliders/slider-desires/slider-desires';
import SliderMoments from 'ToolboxComponents/commons/sliders/slider-moments/slider-moments';
import AutocompleteLocation from 'ToolboxComponents/commons/inputs/autocomplete-location/autocomplete-location';
import AutocompleteProfile from 'ToolboxComponents/commons/inputs/autocomplete-profile/autocomplete-profile';
import { BandeauFiltresWrapper} from 'ToolboxComponents/webapp/pages/search-view/components/bandeau-filtres.styles';
import Header from 'ToolboxComponents/webapp/pages/results-view/components/header/header';
import Text from 'ToolboxComponents/commons/texts/text/text';
import FiltersPanel from 'ToolboxComponents/webapp/pages/results-view/components/filters-panel/filters-panel';

const BandeauFiltres = ({activeProducts,
  setShowFilters,
  handleShowMap,
  activeFilterNumber,
  filterTagsIds,
  filterTags,
  handleFilterTagsActive,
  isOnlySecrets,
  setOnlySecrets,
  tagNames,
  desires,
  defaultDesire,
  moments,
  defaultMoment,
  onSelect,
  locations,
  geolocation,
  isInZone,
  city,
  defaultLocation,
  whiteLabelCity,
  profiles,
  nbSlides,
  slidesWidth,
  defaultProfile,
  onSearchChange,
  secretsButtonActive,
  showFilters,
  showMap,
  headerClicked,
  setHeaderClicked
}) => {

  const theme = useContext(ThemeContext).state;
  const [isMobile, setIsMobile] = useState(null);
  const history = useHistory();
  const[showSliderDesires, setShowSliderDesires]=useState(false);
  const[showSliderMoments, setShowSliderMoments]=useState(false);
  const[showModalDesires, setShowModalDesires]=useState(false);
  const[showModalMoments, setShowModalMoments]=useState(false);
  const[showModalLocations, setShowModalLocations]=useState(false);
  const [search,setSearch] = useContext(WebappContext).usePath('search');
  const [showModalFilters, setShowModalFilters] = useState(false);
  const [language] = useContext(LanguageContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const[profileClicked, setProfileClicked]=useState(false);
  const[locationClicked, setLocationClicked]=useState(false);

  const handleClose = () => {
    setShowModalFilters(false); 
    setShowModalDesires(false);
    setShowModalLocations(false);
    setShowModalMoments(false);}
  

  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  
  const onSelectSecretsOnly = () => {
    setOnlySecrets(!isOnlySecrets);
    handleFilterTagsActive({isOnlySecretsActive: !isOnlySecrets});
  }

  
  return (
      <BandeauFiltresWrapper 
        isMobile={isMobile}
        theme={theme}
      >
      <div className='container mobile boutons-filtres'>
        <div className='row boutons-filtres-row'>
         <button className={`bouton bouton-envies ${showModalDesires === true ? 'active' : ''}`} disabled = {showModalMoments || showModalLocations || headerClicked ? true: false} onClick={()=> setShowModalDesires(!showModalDesires)}>
            <i className="icon adn adn-stars" /> {search.desireId && !Array.isArray(search.desireId) ? <span className='text-button'>{desires.find(desire => desire.id === search.desireId).name}</span> : <Text className='text-button' path={`page.homepage.defaultDesireButton`} /> }<i className="icon adn adn-keyboard-arrow-down" /></button>
            {showModalDesires === true &&
              <div className='modal-container'>
              <div className='modal-filter'>
                  <div className='modal-header'>
                      <div className='text'><strong>{{
                        fr: <span>Vous avez envie de...</span>,
                        en: <span>Want to...</span>
                      }[language]}
                      </strong> </div>
                      <button onClick={handleClose} className='close'  type='button'>
                          <i className='icon adn adn-close' />
                      </button>
                  </div>
              <div className='modal-body'>
                <SliderDesires
                    className='slider-desires'
                    desires={desires}
                    defaultDesire={defaultDesire.id}
                    onSelect={id => {
                      if (whiteLabel.id === 8 && id === 52) {
                        history.push("/catalogue/envie/jop");
                      }
                      onSearchChange({desireId: id}); 
                      setShowSliderDesires(false); 
                      setShowModalDesires(false); 
                    }}  
                  /> 
                </div>    
              </div>
          </div>
            }
          <button className={`bouton bouton-moment ${showModalMoments === true ? 'active' : ''}`} disabled = {showModalDesires || showModalLocations || headerClicked ? true: false} onClick={()=> setShowModalMoments(!showModalMoments)}>
            <i className="icon adn adn-awesome-sun" /><span className='text-button'>{moments.find(moment => moment.id === search.momentId).name}</span>   <i className="icon adn adn-keyboard-arrow-down" /></button>
            {showModalMoments === true &&
              <div className='modal-container'>
              <div className='modal-filter'>
                  <div className='modal-header'>
                      <div className='text'><strong>{{
                        fr: <span>De maintenant à demain soir</span>,
                        en: <span>From now until tomorrow night</span>
                      }[language]}
                      </strong> </div>
                      <button onClick={handleClose} className='close'  type='button'>
                          <i className='icon adn adn-close' />
                      </button>
                  </div>
              <div className='row justify-content-center modal-body'>
                <SliderMoments
                    moments={moments}
                    defaultMoment={defaultMoment}
                    onSelect={id => {onSearchChange({momentId: id}); setShowSliderMoments(false); setShowModalMoments(false);}}
                  />
                </div>    
              </div>
          </div>}
          <button className={`bouton bouton-location ${showModalLocations === true ? 'active' : ''}`} onClick={()=> setShowModalLocations(!showModalLocations)} disabled = {showModalDesires || showModalMoments || headerClicked ? true : false}>
            <i className="icon adn adn-map-marker" /> {typeof locations.find(location => location.id === search.locationId) === 'undefined' ? "Autour de moi" : <span className='text-button'>{locations.find(location => location.id === search.locationId).name}</span>}    <i className="icon adn adn-keyboard-arrow-down" /></button>
              {showModalLocations === true &&
                <div className='modal-container'>
                <div className=' modal-filter modal-location'>
                    <div className=' modal-header'>
                        <div className='text'><strong>
                        {{
                          fr: <span>À proximité de...</span>,
                          en: <span>Next to...</span>
                        }[language]}
                        </strong> </div>
                        <button onClick={handleClose} className='close'  type='button'>
                            <i className='icon adn adn-close' />
                        </button>
                    </div>
                <div className='row justify-content-center modal-body '>
                 <div className='col-12'>
                    <div className='col-12 autocomplete-location'>
                      <AutocompleteLocation
                        locations= {locations}
                        onSelect={location => {
                          const searchUpdate = {
                            locationId: location.id,
                            searchLatitude: location.latitude,
                            searchLongitude: location.longitude
                          };
                          if (!geolocation.isGeolocated) {
                            searchUpdate.userLatitude = location.latitude;
                            searchUpdate.userLongitude = location.longitude;
                          }
                          return onSearchChange(searchUpdate)
                        }}
                        geolocation={geolocation}
                        isInZone={isInZone}
                        city={city}
                        defaultLocation={defaultLocation}
                        whiteLabelCity={whiteLabelCity}
                      />
                    </div>
                    </div>
                  </div>    
                </div>
              </div>
              }
              { language === "fr" &&  whiteLabel.id !== 10 && whiteLabel.id !== 24  &&
                <button className='bouton bouton-secret-only' onClick={() => {onSelectSecretsOnly(); setShowFilters(false);}} disabled = {showModalDesires || showModalMoments || showModalLocations || headerClicked ? true: false}>
                <i className="icon adn adn-star" /> uniquement avec secrets    <i className="icon adn adn-keyboard-arrow-down" />
              </button>
              }
              <button className= {`bouton bouton-plus-filtres ${showModalDesires || showModalMoments || showModalLocations  ? 'is-disabled' : ''}`} onClick={()=> {setShowModalFilters(!showModalFilters); setHeaderClicked(!headerClicked);}}>
                <i className="icon adn adn-filter-list" /> {{
                          fr: <span>+ de filtres</span>,
                          en: <span>More filters</span>
                        }[language]} <i className="icon adn adn-keyboard-arrow-down" />
              </button>
              {showModalFilters === true &&
              <div className={`modal-container`} >
              <div className='modal-filter'>
                  <div className='modal-header'>
                      <div className='text'><strong>{{
                          fr: <span>Affiner les résultats</span>,
                          en: <span>Refine results</span>
                        }[language]}
                        </strong> </div>
                      <button onClick={() => {handleClose(); setHeaderClicked(false);}} className='close'  type='button'>
                          <i className='icon adn adn-close' />
                      </button>
                  </div>
              <div className='row justify-content-center modal-body'>
                  <FiltersPanel
                    isMobile={isMobile}
                    theme={theme}
                    setShowFilters={setShowModalFilters}
                    filterTags={filterTags}
                    handleFilterTagsActive={handleFilterTagsActive}
                    isOnlySecrets={isOnlySecrets}
                    setOnlySecrets={setOnlySecrets}
                    secretsButtonActive={secretsButtonActive}
                    activeProducts={activeProducts}
                    setShowModalFilters={setShowModalFilters}
                    setHeaderClicked={setHeaderClicked}
                    headerClicked={headerClicked}
                  />
              </div>    
            </div>  
          </div>}
          </div>
      </div>
      <div className='desktop boutons-filtres '>
        <div className='row boutons-filtres-row'>
          <button className={`bouton bouton-envies ${showSliderDesires === true ? 'active' : ''}`} disabled = {showSliderMoments || profileClicked || locationClicked || headerClicked ? true: false} onClick={()=> setShowSliderDesires(!showSliderDesires)}>
            <i className="icon adn adn-stars" />{search.desireId && !Array.isArray(search.desireId) ? desires.find(desire => desire.id === search.desireId).name : <Text path={`page.homepage.defaultDesireButton`} /> }          
          </button>
          <button className={`bouton bouton-moment ${showSliderMoments === true ? 'active' : ''}`} disabled = {showSliderDesires || profileClicked || locationClicked  || headerClicked ? true: false} onClick={()=> setShowSliderMoments(!showSliderMoments)}>
            <i className="icon adn adn-awesome-sun" /> {moments.find(moment => moment.id === search.momentId).name}     
          </button>
          <div className={`autocomplete-location ${showSliderDesires || showSliderMoments || profileClicked || headerClicked ? 'is-disabled' : ''}`} >
            <AutocompleteLocation
                locations= {locations}
                onSelect={location => {
                  const searchUpdate = {
                    locationId: location.id,
                    searchLatitude: location.latitude,
                    searchLongitude: location.longitude
                  };
                  if (!geolocation.isGeolocated) {
                    searchUpdate.userLatitude = location.latitude;
                    searchUpdate.userLongitude = location.longitude;
                  }
                  return onSearchChange(searchUpdate)
                }}
                geolocation={geolocation}
                isInZone={isInZone}
                city={city}
                defaultLocation={defaultLocation}
                whiteLabelCity={whiteLabelCity}
                onClick = {() => setLocationClicked(!locationClicked)}
            />
          </div>
          <div className={`autocomplete-profiles ${showSliderDesires || showSliderMoments || locationClicked || headerClicked ? 'is-disabled' : ''}`} > 
            <AutocompleteProfile
                profiles= {profiles}
                onSelect={profile => onSearchChange({
                  profileId: profile.id
                })}
                defaultProfile={defaultProfile}
                onClick = {()=>setProfileClicked(!profileClicked)}
            />
          </div>
            {tagNames !== undefined && (
              <div className={`${showSliderDesires || showSliderMoments || locationClicked || profileClicked ? 'is-disabled' : ''}`} onClick = {()=>{setHeaderClicked(!headerClicked); setShowFilters(!showFilters);}}>
                <Header
                  isMobile={isMobile}
                  theme={theme}
                  activeProducts={activeProducts}
                  setShowFilters={setShowFilters}
                  handleShowMap={handleShowMap}
                  activeFilterNumber={activeFilterNumber}
                  filterTagsIds={filterTagsIds}
                  filterTags={filterTags}
                  handleFilterTagsActive={handleFilterTagsActive}
                  isOnlySecrets={isOnlySecrets}
                  setOnlySecrets={setOnlySecrets}
                />  
                </div>  
              )}
          </div>  
          <div className='container'>
            <div className='row filters'>
              {showSliderDesires === true &&
                <SliderDesires
                  className='slider-desires'
                  desires={desires}
                  defaultDesire={defaultDesire.id}
                  onSelect={id => {
                    if (whiteLabel.id === 8 && id === 52) {
                      history.push("/catalogue/envie/jop");
                    }
                    onSearchChange({desireId: id}); 
                    setShowSliderDesires(false); 
                  }}  
                />
              }
              {showSliderMoments === true &&
                <SliderMoments
                  moments={moments}
                  defaultMoment={defaultMoment}
                  onSelect={id => {onSearchChange({momentId: id}); setShowSliderMoments(false)}}
                />
              } 
          </div> 
        </div> 
      </div>    
    </BandeauFiltresWrapper>
  );
};

BandeauFiltres.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  showMap: PropTypes.bool.isRequired
};



export default BandeauFiltres;