import styled from 'styled-components';

export const MomentWrapper = styled.div`
  width:114px;
  height:114px;
  margin-bottom:80px;
  .btn-search {
    background: none;
    border: 2px solid #e3e3e3;
    margin: 0 10px 0 0;
    padding: 10px;
    width: ${props => (props.isMobile === true ? '95%' : '100%')};
    text-align: center;
    border-radius: 15px;
    color: #000000;
    height:114px;
  }
  span {
    display: block;
  }
  .btn-search i {
    font-size: 6rem;
    height: 53px;
    line-height: 53px;
    width: 53px;
  }
  @media (min-width: 768px) {
    .btn-search i {
      font-size: 6rem;
      height: 115px;
      line-height: 53px;
      width: 53px;
    }
  }
  .btn-search i {
    font-size: 4rem;
    margin-left: auto;
    margin-right: auto;
    height: 53px;
    line-height: 53px;
    width: 53px;
  }
  .btn-search.active, .btn-search:hover {
    background: ${props => { return props.theme.color.primaryColor }};
    border: ${props => { return `2px solid ${props.theme.color.primaryColor}` }};
    color: #fff;
    cursor: pointer;
  }
  .btn-search.date {
    background: #373737;
    color: #fff;
    border: 2px solid #373737;
    max-height: 114px;
    padding:10px;
  }
  .btn-search.date .day-number {
    font-family: ${props => props.theme.font.secondaryFont.name};
    font-size: 40px;
    line-height: 1em;
    margin: 10px 0;
  }
  .btn-search.date .month {
    font-family: ${props => props.theme.font.secondaryFont.name};
    font-size: ${props => `${18*props.theme.font.secondaryFont.scaling/100}px`};
    line-height: 1em;
    text-transform: capitalize;
    margin-top: -10px;
  }
  button[disabled] {
    cursor: not-allowed;
  }
  .search-form-label {
    font-size: 12px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 767px) {
    .btn-search {
      padding: 5px 10px;
    }
    .btn-search.date {
      min-height: 106px;
    }
    .btn-search i {
      font-size: 3rem;
      height: 60px;
      line-height: 60px;
      width: 60px;
    }
    .btn-search.date .day-number {
      font-size: 40px;
    }
    .btn-search.date .month {
      font-size: 14px;
    }
  }
`;
