import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { FiltersPanelWrapper } from 'ToolboxComponents/webapp/pages/results-view/components/filters-panel/filters-panel.styles';
import { WhiteLabelContext, LanguageContext } from 'ToolboxUtils/web/context/context';

const FiltersPanel = ({ setHeaderClicked, headerClicked, setShowFilters, filterTags, handleFilterTagsActive, isMobile, theme, isOnlySecrets, setOnlySecrets, secretsButtonActive, activeProducts }) => {
  const onSelectSecretsOnly = () => {
    setOnlySecrets(!isOnlySecrets);
    handleFilterTagsActive({isOnlySecretsActive: !isOnlySecrets});
  }
  const [language] = useContext(LanguageContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  
  
  return (
    <FiltersPanelWrapper
      isMobile={isMobile}
      theme={theme}
      isOnlySecrets={isOnlySecrets}
      onClick={event =>
        event.target.classList[0] === 'filters-panel' && setShowFilters(false)
      }
    >
      <div className='container-fluid filters-panel'>
        { (whiteLabel.id === 10 || whiteLabel.id === 24)  ? 
          <div className='row justify-content-center filters-wrapper'>
          <div className='filters-body'>
            <div className={`${isMobile ? '' : 'col-12'}`}>
              { !isMobile && 
                <div className='separation'>
                  <hr />
                  <span className='text'>
                    <Text path='page.results.refineResults' />
                  </span>
                  <hr />
                </div>
              }
              <div className='tags'>
                {filterTags.length === 0
                  ? <Text path='page.results.noFilter' />
                  : filterTags.map(({ id, name, number, active }) => (
                      <div
                        key={name}
                        className={`tag ${active === true ? 'active' : ''} ${number === 0 ? 'inactive' : ''}`}
                        onClick={() => number > 0 && handleFilterTagsActive({id: id})}
                      >
                        <div className='title'>{name}</div>
                        <div className='number'>{number}</div>
                      </div>
                    ) ) 
                }
              </div>
              <div className='filters-display-results'>
                <button className='results-btn' onClick={() => {setShowFilters(false); setHeaderClicked(false);}}>
                  {activeProducts.length >= 1
                    ? <Text path='page.results.displayResults' data={{nbProducts: activeProducts.length}} />
                    : <Text path='page.results.displayResult' />
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
        :
        <div className='row filters-wrapper'>
          <div className='filters-body'>
            { !isMobile && language === "fr" && secretsButtonActive && 
              <div className='col-4 secret'>
                <div className='row justify-content-center bouton-secrets-only' onClick={() => {onSelectSecretsOnly(); setShowFilters(false);}} >
                <span className='adn adn-star-circle' />
                <span className='col-12 text'>
                  <Text path='page.results.onlySecret' />
                </span>
                </div>
              </div>
            }
            <div className={`${language === 'fr' ? isMobile ? '' : 'col-8': 'col-12'}`}>
              { !isMobile && 
                <div className='separation'>
                  <hr />
                  <span className='text'>
                    <Text path='page.results.refineResults' />
                  </span>
                  <hr />
                </div>
              }
              <div className='tags'>
                {filterTags.length === 0
                  ? <Text path='page.results.noFilter' />
                  : filterTags.map(({ id, name, number, active }) => (
                      <div
                        key={name}
                        className={`tag ${active === true ? 'active' : ''} ${number === 0 ? 'inactive' : ''}`}
                        onClick={() => number > 0 && handleFilterTagsActive({id: id})}
                      >
                        <div className='title'>{name}</div>
                        <div className='number'>{number}</div>
                      </div>
                    ) ) 
                }
              </div>
              <div className='filters-display-results'>
                <button className='results-btn' onClick={() => {setShowFilters(false); setHeaderClicked(!headerClicked);}}>
                  {activeProducts.length >= 1
                    ? <Text path='page.results.displayResults' data={{nbProducts: activeProducts.length}} />
                    : <Text path='page.results.displayResult' />
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
        
      
      }
        
      </div>
    </FiltersPanelWrapper>
  );
};

FiltersPanel.propTypes = {
  filterTags: PropTypes.array,
  setShowFilters: PropTypes.func,
  handleFilterTagsActive: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  isOnlySecrets: PropTypes.bool.isRequired,
  setOnlySecrets: PropTypes.func.isRequired,
  secretsButtonActive: PropTypes.bool.isRequired,
  activeProducts: PropTypes.array.isRequired,
  headerClicked:PropTypes.bool,
  setHeaderClicked:PropTypes.func
};

export default FiltersPanel;
