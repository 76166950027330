import styled from 'styled-components';

export const MobileMenuWrapper = styled.div`
  display: ${props => (props.isMobile ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 60px;
  top: 120px;
  left: 0;
  z-index: 0;
  background:white;
  color: white;
  padding-left: 10px;
  .edit-search {
    display: flex;
    align-items: center;
    .icon {
      font-size: 40px;
    }
  }
  .filters-map {
    display: flex;
    width: 200px;
    width: ${props => (props.hasFilters ? '200px' : '100px')};
    justify-content: space-around;
    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-left: 1px solid white;
      width: 100px;
      font-size: ${props => `${14*props.theme.font.primaryFont.scaling/100}px`};
      .icon {
        font-size: 30px;
      }
      .texts {
        display: flex;
        justify-content: center;
        width: 100%;
        .nb-actives-filters {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          background: white;
          color: ${props => props.theme.color.primaryColor};
        }
      }
    }
  }
  .map{
    position: fixed;
    text-align:center;
    bottom:82px;
    left:50%;
    background-color:${props => props.theme.color.secondaryColor};;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    padding:5px 15px;
    align-items:center;
    transform: translateX(-50%);
    span{
      margin-right:5px;
    }
  }
`;
