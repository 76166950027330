import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

export const SpecialHeaderWrapper = styled.div`
  padding: 20px 15px 0 20px;
  background: ${props => props.theme.color.tertiaryColor};
  display: flex;
  margin-bottom: ${props => props.pageVisible === 1 ? '40px' : '16px'};
  flex-direction: column;
  .title {
    text-transform: uppercase;
    text-align: center;
    font-family: ${props => props.theme.font.secondaryFont.name};
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
    font-size: 34px;
  }
  .highlight {
    color: ${props => props.theme.color.primaryColor};
  }

  @media (max-width: ${isMobileBreakpoint}px) {
    padding-left: 15px;
    width: 100vw;
    margin-left: -16px;
    .text {
      display: flex;
      .button {
        display: flex;
        align-items: flex-end;
        margin-bottom: 1rem;
        .show-map {
          display: flex;
          flex-direction: column;
          color: white;
          margin-left: 10px;
          padding: 6px 12px;
          border-radius: 6px;
          background: ${props => props.theme.color.primaryColor};
          line-height: 1;
          height: fit-content;
          align-items: center;
          .adn-map {
            font-size: 50px;
          }
          span {
            font-size: ${props => `${18*props.theme.font.primaryFont.scaling/100}px`};
          }
      }
      }
    }
  }
`;
