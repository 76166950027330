import React, { useState, useEffect,useContext } from 'react';
import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";
import { WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import { HeaderWrapper } from 'ToolboxComponents/webapp/pages/results-view/components/header/header.styles';

const Header = ({ isMobile, theme, activeProducts, setShowFilters, handleShowMap,activeFilterNumber, filterTagsIds, filterTags, handleFilterTagsActive, isOnlySecrets, setOnlySecrets }) => {
  const [plural, setPlural] = useState('');
  const [tagsActivated, setTagsActivated] = useState(Array.from(filterTagsIds));
  const [data, setData] = useState({ activeFitlersCount: 0, filterPlural: '' , activePlural: ''});
  const [isFirstRowVisible, showFirstRow] = useState(false);
  const [isSecondRowVisible, showSecondRow] = useState(false);
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  


  useEffect(() => {
    if (activeFilterNumber > 1) {
      setPlural('s');
    } else {
      setPlural('');
    }
  }, [activeFilterNumber]);

  useEffect(() => {
    setTagsActivated(Array.from(filterTagsIds))
  }, [filterTagsIds]);

  useEffect(() => {
    setData({ activeFilterNumber, filterPlural: plural ,activePlural: plural});
  }, [activeFilterNumber, plural]);

  useEffect(() => {
    if (isMobile) {
      showFirstRow(false);
    } else {
      showFirstRow(true);
    }
  }, [isMobile]);
  useEffect(() => {
    if (activeFilterNumber === 0 && !isOnlySecrets && !isMobile) {
      showSecondRow(true);
    } else {
      showSecondRow(false);
    }
  }, [activeFilterNumber, isOnlySecrets, isMobile]);

  const onSelectSecretsOnly = () => {
    setOnlySecrets(!isOnlySecrets);
    handleFilterTagsActive({isOnlySecretsActive: !isOnlySecrets});
  }

  return (
    <HeaderWrapper isMobile={isMobile} theme={theme} isFirstRowVisible={isFirstRowVisible} isSecondRowVisible={isSecondRowVisible} >
      <div className='results-header'>
        {isMobile === false &&
          <div className='top'>
            <div className='nb-results'>
              <Text
                path='page.results.resultsCount'
                data={{
                  resultsCount: '' + activeProducts.length + (activeProducts.length >= 100 ? '+' : ''),
                  resultPlural: activeProducts.length > 1 ? 's' : '',
                }}
              />
            </div>
          </div>
        }
        {activeFilterNumber === 0 && isOnlySecrets === false && isMobile === false &&
          <div className='bottom'>
            <div className='bouton-other-filters' onClick={() => setShowFilters(true)}>
              <div className='filters-drop-down-content'>
                <div className='filters-drop-down-title'>
                  <Text path='page.results.selectFilters' />
                </div>{' '}
                {activeFilterNumber > 0 && (
                  <div className='active'>
                    <Text path='page.results.activeFiltersCount' data={data} />
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      </div>
      {activeFilterNumber === 0 && isOnlySecrets === false
        ? null
        : <div className='filters-block'>
            <span className='adn adn-filter icon' />
            <div className='filters'>
              {isOnlySecrets &&  whiteLabel.id !== 10 && whiteLabel.id !== 24  &&
                <span className='filter'>
                  <Text path='page.results.withSecrets' />
                  <span className='adn adn-close-circle delete-tag' onClick={() => onSelectSecretsOnly()}/>
                </span>
              }
              {tagsActivated.map(id => (
                <span key={id} className='filter'>
                  {filterTags[filterTags.findIndex(tag => tag.id === id)].name}
                  <span className='adn adn-close-circle delete-tag' onClick={() => handleFilterTagsActive({id: id})}/>
                </span>
              ))}
              <span className='adn adn-pencil filter edit-filters' onClick={() => setShowFilters(true)}> </span>
            </div>
          </div>
      }
    </HeaderWrapper>
  );
};

Header.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  activeProducts: PropTypes.array,
  setShowFilters: PropTypes.func,
  handleShowMap: PropTypes.func,
  activeFilterNumber: PropTypes.number,
  filterTagsIds: PropTypes.object.isRequired,
  filterTags: PropTypes.array.isRequired,
  handleFilterTagsActive: PropTypes.func.isRequired,
  isOnlySecrets: PropTypes.bool.isRequired,
  setOnlySecrets: PropTypes.func.isRequired,
};

export default Header;
