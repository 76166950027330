import React, { useState, useEffect, useContext } from 'react';
import formatSchedule from 'ToolboxUtils/web/formats/format-schedule';
import { LanguageContext, ThemeContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import PropTypes from 'prop-types';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";
import CardWrapper from './simple-product-card.style';
import businessHours from './assets/business-hours.svg';
import distanceIcon from './assets/distance.svg';
import distance from 'ToolboxUtils/web/formats/distance';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import slugify from 'url-slug';

const ProductCard = props => {
  const { product, isEvent } = props;
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();
  const [scheduleStatus, setScheduleStatus] = useState('');
  const [schedulePath, setSchedulePath] = useState(null);
  const [scheduleHour, setScheduleHour] = useState({});
  const [scheduleDate, setScheduleDate] = useState({});
  const [hasSchedule, setHasSchedule] = useState(false);


  useEffect(() => {
    if (product.startTime === null && product.endTime === null) {
      setScheduleStatus('off');
      setSchedulePath('generic.circuits.closed');
      setScheduleHour(null);
      setScheduleDate(null);
      return;
    }
    setHasSchedule(true);

    const schedule = formatSchedule(
      isEvent, {
      startTime: product.startTime,
      endTime: product.endTime,
      language,
    });

    setScheduleStatus(schedule.status);
    setSchedulePath(schedule.message);
    setScheduleHour(schedule.hour);
    setScheduleDate(schedule.date);
    // eslint-disable-next-line
  }, [product]);


  return (
    <Anchor code='product' pageParams={`/${product.id}/${slugify(product.title, {lower: true})}`}>
      <CardWrapper {...props} theme={theme}>
        <div className='row'>
        <div className="col-4 header">
          <ImageResize className={`image`} src={product.photo} />
        </div>
        <div className='col-8 content-col'>
        <div className="content">
          <div className="title">{product.title}</div>
          <div className="distance">
            <span className='adn adn-location-arrow icon' />
            {distance(product.distance)}
          </div>
        </div>
        <div className="footer">
          {hasSchedule && scheduleHour !== null &&
          <div className="business-hours">
            <span className='adn adn-clock icon' />
            {schedulePath !== null && (
              <Text
                path={schedulePath}
                data={{ time: scheduleHour, date: scheduleDate }}
                className={`is-${scheduleStatus}`}
              />
            )}
          </div>}
        </div>
        </div>
        </div>
        
        
      </CardWrapper>
    </Anchor>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object, //all product information
};

export default ProductCard;
