import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from "ToolboxUtils/web/context/context";
import PropTypes from "prop-types";
import Slider from "ToolboxComponents/commons/sliders/slider/slider/slider";
import MomentSlide from 'ToolboxComponents/commons/cards/moment-product-card/moment-product-card';

const SliderMoments = props => {
  const { primaryColor } = useContext(ThemeContext).state.color;
  const [momentSelected, setMomentSelected] = useState(props.defaultMoment);

  return (
    <Slider
      desktop={{
        slidesPerView: 9,
        spaceBetween: 18,
        dots:false,
        arrows: true,
        arrowsMiddle: true,
        arrowsDisabledHide: true,
        slidesPerGroup: 9,
      }}
      mobile={{
        slidesPerView: 2.5,
        slidesPerGroup: 2,
      }}
      dotsColorActive={primaryColor}
      slideTo={props.defaultMoment}
    >
      {props.moments.map((moment, index) => (
        <MomentSlide
          key={index}
          index={index}
          moment={moment}
          setMomentSelected={(id) => {setMomentSelected(id), props.onSelect(id)}}
          momentSelected={momentSelected}
        />
      ))}
    </Slider>
  );
};

SliderMoments.propTypes = {
  moments: PropTypes.array.isRequired,
  defaultMoment: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SliderMoments;
