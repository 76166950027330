import formatShortDate from 'ToolboxUtils/web/formats/format-short-date'

const today = new Date();
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
const now = today.getHours() * 3600 + today.getMinutes() * 60;

export const createMoments = (props, language) => {
  let moments = [{
      name: {fr: 'aujourd\'hui', en: 'today'}[language],
      className: 'calendar',
      date: formatShortDate({date:today, language, options: {day: 'numeric'}}),
      month: formatShortDate({date:today, language, options: {month: 'long'}}),
    }
  ];
  for (let moment of props) {
    if (moment.endSecond === null || (moment.endSecond > now && !moment.isTomorrow)) { // null : cas "maintenant"
      moments.push({
        id: moment.id,
        name: moment.name,
        picture: moment.picture,
      });
    }
  }
  moments.push({
    name: {fr: 'demain', en: 'tomorrow'}[language],
    className: 'calendar',
    date: formatShortDate({date:tomorrow, language, options: {day: 'numeric'}}),
    month: formatShortDate({date:tomorrow, language, options: {month: 'long'}}),
  });
  for (let moment of props) {
    if (moment.isTomorrow) {
      moments.push({
        id: moment.id,
        name: moment.name,
        picture: moment.picture,
      });
    }
  }
  return moments;
}
