import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

export const NoProductWrapper = styled.div`
  margin: 20px 0 0 40px;
  padding: 10px;
  box-shadow: 2px 2px 20px 5px rgba(0,0,0,0.1);
  .product-photo {
    width: 100%;
  }
  .description {
    padding: 20px 10px 0 10px;
    p {
      margin: 0;
    }
    .icon-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      font-size: 50px;
      color: ${props => `${props.theme.color.primaryColor}`};
    }
  }
  @media (max-width: ${isMobileBreakpoint-1}px) {
    margin: 20px 10px 0 10px;
  }
`;
