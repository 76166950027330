import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { WebappContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import styled from 'styled-components';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import SimpleProductCard from 'ToolboxComponents/commons/cards/simple-product-card/simple-product-card';
import Map from 'ToolboxComponents/commons/map/map';
import { NB_PRODUCTS_RESULTS_PAGE } from 'ToolboxParams/globals';
import { MobileMapWrapper } from 'ToolboxComponents/webapp/pages/results-view/components/mobile-map/mobile-map.style';

const MobileMap = props => {
  const { handleShowMap, setExternalShowMap, userLocation, selectedMarker, activeProducts, handleMarkerClick, selectedSlide, handleSwipe, pageVisible, nbProducts, previousPage, nextPage, theme, setSelectedMarkerOnPageChange, gpx, isCircuit, isEvent } = props;

  const webappContext = useContext(WebappContext);
  const whiteLabel = useContext(WhiteLabelContext).state;

  const [MapGpx, setMapGpx] = useState(null);

  const [sliderElements, setSliderElements] = useState([]);
  const [pageNumber, setPageNumber] = useState(pageVisible ? pageVisible : null);

  useEffect(() => {
    if (isCircuit && gpx) {
      setMapGpx(React.lazy(() => import('ToolboxComponents/commons/map-gpx/map-gpx')));
    }
  }, [isCircuit, gpx]);

  useEffect(() => {
    setPageNumber(pageVisible);
  }, [pageVisible]);
  useEffect(() => {
    if (pageNumber !== pageVisible) {
      if (pageVisible === 1) {
        setSelectedMarkerOnPageChange(0);
      } else {
        setSelectedMarkerOnPageChange(1);
      }
    }
  }, [activeProducts]);
  useEffect(() => {
    let elements = [];
    if (pageVisible !== 1) {
      elements.push(
        <div className='page-switch' key='prev'>
          <span className='adn adn-arrow-left icon' onClick={() => previousPage()} />
          {`Résultats ${(pageVisible-2)*NB_PRODUCTS_RESULTS_PAGE+1} - ${(pageVisible-1)*NB_PRODUCTS_RESULTS_PAGE}`}
        </div>
      );
    }
    for (const [index, product] of activeProducts.entries()) {
      elements.push(
        <SimpleProductCard
          key={`slide-product--${index}`}
          product={product}
          isEvent={isEvent}
        />
      )
    }
    if (pageVisible !== Math.ceil(nbProducts/NB_PRODUCTS_RESULTS_PAGE)) {
      elements.push(
        <div className='page-switch' key='next'>
          <span className='adn adn-arrow-right icon' onClick={() => nextPage()} />
          {`Résultats ${(pageVisible)*NB_PRODUCTS_RESULTS_PAGE+1} - ${pageVisible+1 !== Math.ceil(activeProducts.length/NB_PRODUCTS_RESULTS_PAGE) ? (pageVisible+1)*NB_PRODUCTS_RESULTS_PAGE : activeProducts.length}`}
        </div>
      );
    }
    setSliderElements(elements);
  }, [activeProducts]);

  const handleMapHide = () => {
    handleShowMap(false);
    webappContext.setElement('resultsView.selectedSlideId', null);
    if (setExternalShowMap !== undefined) {
      setExternalShowMap(false);
    }
  };
  return (
    <MobileMapWrapper theme={theme}>
      <div className="mobile-map">
        <div className="mobile-map--header" onClick={() => handleMapHide()}>
          <span className="adn adn-close mobile-map--close" />
          {whiteLabel.textualContent.page.results.mapBack}
        </div>
        <div className="mobile-map--map">
          {isCircuit && gpx !== null
            ? MapGpx !== null && <MapGpx
                clickLocation={id => handleMarkerClick(id+(pageVisible-1)*10)}
                gpx={gpx}
                itemLocations={activeProducts.map(product => ({
                  latitude: product.latitude,
                  longitude: product.longitude,
                }))}
                selectedSlide={selectedMarker}
                userLocation={userLocation}
              />
            : <Map
                userLocation={userLocation}
                selected={selectedMarker}
                itemLocations={activeProducts.map(product => ({
                  latitude: product.latitude,
                  longitude: product.longitude,
                }))}
                clickLocation={id => handleMarkerClick(id)}
                pageVisible={pageVisible}
              />
          }
        </div>
        <div className="mobile-map--slider">
          <Slider
            key={'slider-product'}
            slideTo={selectedMarker}
            getCurrentSlideIndex={id => handleSwipe(id)}
            mobile={{
              spaceBetween: 14,
            }}
            desktop={{
              freeMode: true,
            }}
          >
            {sliderElements}
          </Slider>
        </div>
      </div>
    </MobileMapWrapper>
  );
};

MobileMap.propTypes = {
  handleShowMap: PropTypes.func,
  userLocation: PropTypes.object,
  selectedMarker: PropTypes.number,
  activeProducts: PropTypes.array,
  handleMarkerClick: PropTypes.func,
  selectedSlide: PropTypes.number,
  handleSwipe: PropTypes.func,
  pageVisible: PropTypes.number,
  nbProducts: PropTypes.number,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  theme: PropTypes.object.isRequired,
  setSelectedMarkerOnPageChange: PropTypes.func.isRequired,
  gpx: PropTypes.string,
  isCircuit: PropTypes.bool,
};

export default MobileMap;
