import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { WebappContext, ThemeContext, GeolocationContext,LanguageContext,WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import ResultsWrapper, { ResultsSidebarWrapper } from './results-view.style';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Map from 'ToolboxComponents/commons/map/map';
import Header from 'ToolboxComponents/webapp/pages/results-view/components/header/header';
import SpecialHeader from 'ToolboxComponents/webapp/pages/results-view/components/special-header/special-header';
import ListCards from 'ToolboxComponents/webapp/pages/results-view/components/list-cards/list-cards';
import FiltersPanel from 'ToolboxComponents/webapp/pages/results-view/components/filters-panel/filters-panel';
import MobileMenu from 'ToolboxComponents/webapp/pages/results-view/components/mobile-menu/mobile-menu';
import NoProduct from 'ToolboxComponents/webapp/pages/results-view/components/no-product/no-product';
import MobileMap from 'ToolboxComponents/webapp/pages/results-view/components/mobile-map/mobile-map';
import { NB_PRODUCTS_RESULTS_PAGE } from 'ToolboxParams/globals';
import BandeauFiltres from 'ToolboxComponents/webapp/pages/search-view/components/bandeau-filtres';
import { createMoments } from 'ToolboxUtils/web/moments/create-moments';
import sortProducts from 'ToolboxUtils/web/products/sort-products';
import { getNbSlidesAndWidth } from 'ToolboxUtils/web/slider-profiles/getNbSlidesAndWidth';
import Text from 'ToolboxComponents/commons/texts/text/text';
import Loader from 'ToolboxComponents/commons/loader/loader';
import { has } from 'core-js/core/dict';
import { is } from 'core-js/core/object';


const Results = props => {
  const theme = useContext(ThemeContext).state;
  const [search] = useContext(WebappContext).usePath('search');
  const [userLocation] = useContext(GeolocationContext).usePath('coordinates');
  const [geolocation] = useContext(GeolocationContext).usePath();
  const isGeolocated = useContext(GeolocationContext).state.isGeolocated;
  const webappContext = useContext(WebappContext);
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const {
    products,
    tagNames,
    height = (props.isSearch ? 'calc(100vh - 191px)' : 'calc(100vh - 80px)'),
    marginTopMobile = '0',
    marginTopDesktop = '80px',
    desktopMapStickyTop = '80px',
    nbProductsPage = NB_PRODUCTS_RESULTS_PAGE,
    externalShowMap,
    setExternalShowMap,
    addFavorite,
    deleteFavorite,
    isFavorites,
    hasFilters = true,
    isSearch = false,
    isCluster = false,
    isCatalog,
    isEvent,
    isSelectedProduct,
    categoryName,
    categoryDescription,
    page,
    circuit,
    onDesireSelect,
    isReloadingProducts,
    hasNoDescription,
    targetPage = 'product'
  } = props;

  const [showFilters, setShowFilters] = useState(false);
  const [headerClicked, setHeaderClicked] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(-1);
  const [selectedMarkerSlide, setSelectedMarkerSlide] = useState(-1);
  const [selectedMarker, setSelectedMarker] = useState(0);
  const [slideSwipe, setSlideSwipe] = useState(true);
  const [filterTags, setFilterTags] = useState([]);
  const [filterTagsIds, setFilterTagsIds] = useState(new Set());
  const [activeFilterNumber, setActiveFilterNumber] = useState(0);
  const [activeProducts, setActiveProducts] = useState(products);
  const [unsortedProducts, setUnsortedProducts] = useState(products);
  const [mobileSliderHeight, setMobileSliderHeight] = useState(0);
  const [isOnlySecrets, setOnlySecrets] = useState(false);
  const [secretsButtonActive, setSecretsButtonActive] = useState(true);
  const [hasOneHighlightProductOnly, sethasOneHighlightProductOnly] = useState(false);
  const [hasNoHighlightProduct, sethasNoHighlightProduct] = useState(false);
  const [isSecretHighlightOnly, setIsSecretHighlightOnly] = useState(false);
  const [maxSecretCount, setMaxSecretCount] = useState(0);
  const [pagesProducts, setPagesProducts] = useState([]);
  const [pageVisible, setPageVisible] = useState(1);
  const [productsList, setProductsList] = useState(products);
  const [MapGpx, setMapGpx] = useState(null);
  const [language] = useContext(LanguageContext).usePath();
  const [location, setLocation] = useState();
  const [moments] = useState(props.momentsList ? createMoments(props.momentsList, language) : null);
  const [newProfileSlideWidth, setProfileSlideWidth] = useState(null);
  const [nbProfileSlides, setProfileSlides] = useState(null);
  const [profile, setProfile] = useState();

  useEffect(() => {
    setUnsortedProducts(products);
    setFilterTags([]);
    setFilterTagsIds(new Set());
    setActiveFilterNumber(0);
    setPageVisible(1);
    setSelectedSlide(-1);
  }, [products]);

  useEffect(() => {
    const {hasOneHighlightProductOnly, hasNoHighlightProduct, sortedProducts, isSecretHighlightOnly, maxSecretCount} = sortProducts(unsortedProducts, isGeolocated, whiteLabel.isInZone, search ? search.locationId : null);
    sethasOneHighlightProductOnly(hasOneHighlightProductOnly);
    sethasNoHighlightProduct(hasNoHighlightProduct);
    setIsSecretHighlightOnly(isSecretHighlightOnly);
    setMaxSecretCount(maxSecretCount);
    setActiveProducts(sortedProducts);
  }, [unsortedProducts]);

  useEffect(() => {
    setTimeout(function() {
      let data = getNbSlidesAndWidth(props.profiles ? props.profiles.length : 0);
      setProfileSlides(data.nbProfileSlides);
      setProfileSlideWidth(data.newProfileSlideWidth);
    }, 100);
    // eslint-disable-next-line
  }, [(document.getElementsByClassName('slider-profiles')[0] && document.getElementsByClassName('slider-profiles')[0].offsetWidth)]);

  useEffect(() => {
    if (profile) {
      props.onProfileSelect(profile);
    }
    // eslint-disable-next-line
  }, [profile]);

  const bodyElement = document.querySelector(`body`);
  const navElement = document.querySelector(`#main-nav`);

  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    setIsMobile(isMobileView());

    if (isMobile) {
      setShowMap(!!webappContext.state.resultsView.showMap);
    }
    if (webappContext.state.resultsView.selectedSlideId !== null && isMobile === true ) {
      setSelectedSlide(webappContext.state.resultsView.selectedSlideId);
    } else if (webappContext.state.resultsView.activeProducts !== null && isSearch) {
      setPageVisible(1);
      setUnsortedProducts([...webappContext.state.resultsView.activeProducts]);
      setActiveFilterNumber(webappContext.state.resultsView.activeFilterNumber);
    }

    if (webappContext.state.resultsView.filterTags !== undefined && hasFilters) {
      setFilterTags(webappContext.state.resultsView.filterTags);

      const filterTagsIdsTemp = new Set();
      for (const tag of webappContext.state.resultsView.filterTags) {
        if (tag.active) {
          filterTagsIdsTemp.add(tag.id);
        }
      }
      
      setFilterTagsIds(filterTagsIdsTemp);
    }
   

    if (webappContext.state.resultsView.isOnlySecrets !== undefined) {
      setOnlySecrets(webappContext.state.resultsView.isOnlySecrets);
    }

    if (webappContext.state.resultsView.selectedListCardId !== undefined && webappContext.state.resultsView.selectedListCardId !== null) {
      const selectedIdElement = document.getElementById(`slide-product--${webappContext.state.resultsView.selectedListCardId}`);
      selectedIdElement.scrollIntoView({ block: 'center' });
    }
    if (webappContext.state.resultsView.pageVisible) {
      setPageVisible(webappContext.state.resultsView.pageVisible);
      setSelectedMarker(webappContext.state.resultsView.selectedSlideId);
    }

  }, [isMobile]);

  useEffect(() => {
    setProductsList(products);
    if (page !== 'results') {
      // setPageVisible(1);
      // webappContext.setElement('resultsView.pageVisible', 1);
      // webappContext.setElement('resultsView.selectedListCardId', undefined);
      // webappContext.setElement('resultsView.selectedSlideId', undefined);
    }
  }, [products]);
  useEffect(() => {
    if (isFavorites === true) {
      setPageVisible(1);
    }
    setUnsortedProducts(productsList);
  }, [productsList]);

  //when open/close map, make sure the selectedListCardId is set to null so go back to page top
  useEffect(() => {
    webappContext.setElement('resultsView.selectedListCardId', undefined);
  }, [showMap]);

  useEffect(() => {
    if (circuit && circuit.itinerary !== null) {
      setMapGpx(React.lazy(() => import('ToolboxComponents/commons/map-gpx/map-gpx')));
    }
  }, [circuit]);

 
  useEffect(() => {
    if (tagNames !== undefined) {
      let filterTagsTemp = [...tagNames];
      //get filterTags IDs & Names

      //get filterTags Number
      filterTagsTemp = tagNames.map(filterTag => ({
        ...filterTag,
        number: 0,
      }));

      productsList.forEach(product => {
        if (product.tags !== null) {
          product.tags.forEach(tag => {
            filterTagsTemp = filterTagsTemp.map(filterTag =>
              tag === filterTag.id
                ? { ...filterTag, number: filterTag.number + 1 }
                : { ...filterTag },
            );
          });
        }
      });
      filterTagsTemp = filterTagsTemp.filter(tag => tag.number > 0);
      setFilterTags(filterTagsTemp);
      webappContext.setElement('resultsView.filterTags', filterTagsTemp);
      
    }
    
    let productsHaveSecrets = false;
    for (const product of productsList) {
      if (product.secretCount > 0) {
        productsHaveSecrets = true;
      }
    }
    /*if (!productsHaveSecrets) {
      setSecretsButtonActive(false);
    }*/
  
   
  }, [products, productsList]);
  
  

  useEffect(() => {
    if (document.getElementsByClassName('mobile-map--slider').length > 0) {
      setMobileSliderHeight(
        document.getElementsByClassName('mobile-map--slider')[0].offsetHeight,
      );
    }

    document.ontouchmove = event => {
      if (showMap === true) {
        event.preventDefault();
      } else {
        return true;
      }
    };
  }, [showMap]);

  useEffect(() => {
    if (isMobile === true && bodyElement !== null && navElement !== null) {
      if (showFilters === true  || showMap === true) {
        bodyElement.setAttribute('style', 'overflow: hidden;');
        navElement.setAttribute('style', 'display: none;');
      }

      if (showFilters === false && showMap === false) {
        bodyElement.setAttribute('style', '');
        navElement.setAttribute('style', '');
      }
    }

    // eslint-disable-next-line
  }, [showFilters, showMap]);

  
  useEffect(() => {
    if (externalShowMap !== undefined) {
      setShowMap(externalShowMap);
      webappContext.setElement('resultsView.selectedSlideId', 0);
    }
  }, [externalShowMap]);

  useEffect(() => {
    //cleanup
    return () => {
      bodyElement.setAttribute('style', '');
      navElement.setAttribute('style', '');
    };
  }, []);

  useEffect(() => {
    setPagesProducts([]);
    const nbPages = Math.ceil(activeProducts.length/nbProductsPage);
    if (nbPages > 1) {
      for (let page = 0; page < nbPages; page++) {
        const products = activeProducts.slice(page*nbProductsPage, (page*nbProductsPage + nbProductsPage));
        setPagesProducts(oldPages => [...oldPages, products]);
      }
    } else {
      setPagesProducts([activeProducts])
    }
  }, [activeProducts]);

  useEffect(() => {
    setPageVisible(1);
  }, [window.location]);

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
    if (!isMobile) {
      //setSelectedSlide((pageVisible-1)*10);
    }
  }, [pageVisible]);

  const handleShowMap = flag => {
    setShowMap(flag);
    webappContext.setElement('resultsView.showMap', flag)
    if (flag) {
      webappContext.setElement('resultsView.selectedSlideId', 0);
    }
  };

  const toggleFavoriteActive = addFavorite && (({active, productId}) => {
    if (active === true) {
      deleteFavorite(productId);
    } else {
      addFavorite(productId);
    }
  });

  const handleMarkerClick = id => {
    setSelectedMarker(id);
    setSelectedMarkerSlide(id);
    setSlideSwipe(false);
    setSelectedSlide(id);
    webappContext.setElement('resultsView.selectedSlideId', id);
  };

  const handleSwipe = id => {
    if (slideSwipe === false) {
      if (selectedSlide === id) {
        setSlideSwipe(true);
        setSelectedMarker(id);
        webappContext.setElement('resultsView.selectedSlideId', id);
      }
    } else {
      setSelectedMarker(id);
      webappContext.setElement('resultsView.selectedSlideId', id);
    }
  };

  const handleHover = id => {
    setSelectedSlide(id);
    setSelectedMarker(id);
    webappContext.setElement('resultsView.selectedSlideId', id);
  };

  const handleFilterTagsActive = ({id, isOnlySecretsActive}) => {
    //reset the mobile slider in case it was used prior to the filtering
    setSelectedSlide(0);
    // webappContext.setElement('resultsView.selectedSlideId', id);

    //get all active tags ids
    const filterTagsIdsTemp = new Set(filterTagsIds);

    //activate on/off tags + update active tags ids set
    let filterTagsTemp = null;
    if (id !== undefined) {
      filterTagsTemp = filterTags.map(filterTag => {
        if (filterTag.id === id) {
          const isActive = !filterTag.active;
          if (isActive === true) {
            filterTagsIdsTemp.add(id);
          } else {
            filterTagsIdsTemp.delete(id);
          }
          return { ...filterTag, active: !filterTag.active };
        }
        return { ...filterTag };
      });
    } else {
      filterTagsTemp = filterTags;
    }

    //reset the number on every tag
    filterTagsTemp = filterTagsTemp.map(filterTag => ({
      ...filterTag,
      number: 0,
    }));

    //list of all products that have at least all fliterTagsIdTemp tags
    let activeTagsProducts = productsList.filter(({ tags }) => {
      return Array.from(filterTagsIdsTemp).every(
        tag => tags !== null && tags.includes(tag),
      );
    });

    

    if (isOnlySecrets && isOnlySecretsActive === undefined || isOnlySecretsActive) {
      activeTagsProducts = activeTagsProducts.filter(product => product.secretCount > 0);
    }
    

    //set the number of active products in each tags

    activeTagsProducts.forEach(product => {
      if (product.tags !== null) {
        product.tags.forEach(tag => {
          filterTagsTemp = filterTagsTemp.map(filterTag =>
            tag === filterTag.id
              ? { ...filterTag, number: filterTag.number + 1 }
              : { ...filterTag },
          );
        });
      }
    });

    //update the state
    setPageVisible(1);
    setUnsortedProducts(activeTagsProducts);
    setActiveProducts(activeTagsProducts);
    webappContext.setElement('resultsView.activeProducts', activeTagsProducts);
    setFilterTags(filterTagsTemp);
    webappContext.setElement('resultsView.filterTags', filterTagsTemp);
    webappContext.setElement('resultsView.isOnlySecrets', isOnlySecrets);
    setActiveFilterNumber(
      filterTagsTemp.filter(filterTag => filterTag.active === true).length,
    );
    webappContext.setElement(
      'resultsView.activeFilterNumber',
      filterTagsTemp.filter(filterTag => filterTag.active === true).length,
    );
    setFilterTagsIds(filterTagsIdsTemp);
    
  };
  const onClickListCard = id => {
    //set context selectedListCardId
    webappContext.setElement('resultsView.selectedListCardId', id);
  };

  const previousPage = () => {
    if (pageVisible > 1) {
      setPageVisible(pageVisible-1);
      webappContext.setElement('resultsView.pageVisible', pageVisible-1);
    }
  };

  const nextPage = () => {
    if (pageVisible !== Math.ceil(activeProducts.length/NB_PRODUCTS_RESULTS_PAGE)) {
      setPageVisible(pageVisible+1)
      webappContext.setElement('resultsView.pageVisible', pageVisible+1);
    }
  };

  const setSelectedMarkerOnPageChange = id => {
    setSelectedMarker(id);
    webappContext.setElement('resultsView.selectedSlideId', id);
  };
 
  useEffect(() => {
    if (location) {
      props.onLocationSelect(location);
    }
    // eslint-disable-next-line
  }, [location]);

  if (pagesProducts.length === 0) {
    return <div></div>
  }

  return (
    <ResultsWrapper
      {...props}
      isSearch={isSearch}
      isMobile={isMobile}
      mobileSliderHeight={mobileSliderHeight}
      theme={theme}
      pageVisible={pageVisible}
      marginTopMobile={marginTopMobile}
      marginTopDesktop={marginTopDesktop}
      desktopMapStickyTop={desktopMapStickyTop}
      height={height}
      hasFilters={hasFilters}
      isGPX={circuit && circuit.itinerary}
    >
    <div className='results-container'>
      {isMobile && showMap === false &&
          <div className='map' onClick={() => handleShowMap(true)}>
            <Text path='page.results.map' />
            <span className='adn adn-map icon' />
          </div> 
        }
    {!props.specialHeader && search &&
      <BandeauFiltres 
      isMobile={isMobile}
      theme={theme}
      activeProducts={activeProducts}
      setShowFilters={setShowFilters}
      setHeaderClicked={setHeaderClicked}
      headerClicked={headerClicked}
      showFilters={showFilters}
      handleShowMap={handleShowMap}
      activeFilterNumber={activeFilterNumber}
      filterTagsIds={filterTagsIds}
      filterTags={filterTags}
      handleFilterTagsActive={handleFilterTagsActive}
      isOnlySecrets={isOnlySecrets}
      setOnlySecrets={setOnlySecrets}
      tagNames={tagNames}
      desires={props.desires}
      defaultDesire={props.defaultDesire}
      onDesireSelect={id=>onDesireSelect(id)}
      moments={moments}
      defaultMoment={props.defaultMoment}
      onSearchChange={props.onSearchChange}
      locations={props.locations}
      geolocation={geolocation}
      isInZone={whiteLabel.isInZone}
      city={whiteLabel.city}
      defaultLocation={props.defaultLocation}
      whiteLabelCity={whiteLabel.city.id}
      profiles={props.profiles}
      nbSlides={nbProfileSlides}
      slidesWidth={newProfileSlideWidth}
      onProfileSelect={id => setProfile(id)}
      defaultProfile={props.defaultProfile}
      secretsButtonActive={secretsButtonActive}
      showMap={showMap}
    />
    }
    {isReloadingProducts ? <Loader /> :
    <div className='row-results-sidebar'>
    <div className='row-sidebar'>
      {/*
      <Text
        path='page.results.resultsCount'
        data={{resultsCount: activeProducts.length, resultPlural: activeProducts.length > 1 ? 's' : ''}}
      />
      */}
      <ResultsSidebarWrapper
        {...props}
        isMobile={isMobile}
        mobileSliderHeight={mobileSliderHeight}
        pageVisible={pageVisible}
        theme={theme}
        id={`results-sidebar`}
        height={height}
      >
        {categoryName &&
          <div className='category-name'>
            <span>{`${categoryName} - ${productsList.length}${productsList >= 100 ? '+' : ''} résultat${productsList.length > 1 ? 's' : ''}`}</span>
          </div>
        }
        {categoryDescription &&
          <div className='category-description'>
            <span>{`${categoryDescription} `}</span>
          </div>
        }
        {props.specialHeader && (
          <SpecialHeader hasNoDescription={hasNoDescription} isMobile={isMobile} handleShowMap={handleShowMap} {...props.specialHeader} pageVisible={pageVisible} />
        )}
        {(isFavorites === true || isCatalog === true) && productsList.length === 0 && (
          <NoProduct theme={theme} isFavorites={isFavorites} />
        )}
                    <ListCards
              activeProducts={pagesProducts[pageVisible-1]}
              handleHover={handleHover}
              toggleFavoriteActive={toggleFavoriteActive}
              selectedSlide={selectedSlide}
              selectedMarkerSlide={selectedMarkerSlide}
              onClickListCard={onClickListCard}
              pageVisible={pageVisible}
              isCluster={isCluster}
              isEvent={isEvent}
              isSelectedProduct={isSelectedProduct}
              targetPage={targetPage}
              hasOneHighlightProductOnly={hasOneHighlightProductOnly}
              hasNoHighlightProduct={hasNoHighlightProduct}
              isSecretHighlightOnly={isSecretHighlightOnly}
              maxSecretCount={maxSecretCount}
              isMobile={isMobile}
            />

        {Math.ceil(activeProducts.length/nbProductsPage) > 1 &&
          <div className='pages-scroll'>
            <span>
              {`Résultats ${(pageVisible-1)*nbProductsPage+1} - ${pageVisible !== Math.ceil(activeProducts.length/nbProductsPage) ? (pageVisible)*nbProductsPage : activeProducts.length}`}
            </span>
            <div className='arrows'>
              <span className={'adn adn-arrow-left icon' + (pageVisible === 1 ? ' deactivate' : '')} onClick={() => previousPage()} />
              <span className={'adn adn-arrow-right icon' + (pageVisible === Math.ceil(activeProducts.length/nbProductsPage) ? ' deactivate' : '')} onClick={() => nextPage()} />
            </div>
          </div>
        }
        {showFilters === true && (
          <FiltersPanel
            isMobile={isMobile}
            theme={theme}
            setShowFilters={setShowFilters}
            filterTags={filterTags}
            handleFilterTagsActive={handleFilterTagsActive}
            isOnlySecrets={isOnlySecrets}
            setOnlySecrets={setOnlySecrets}
            secretsButtonActive={secretsButtonActive}
            activeProducts={activeProducts}
            setHeaderClicked={setHeaderClicked}
            headerClicked={headerClicked}
          />
        )}
      </ResultsSidebarWrapper>
      </div>
      
      {isMobile === false &&
        <div className="desktop-map">
          {circuit && circuit.itinerary !== null && MapGpx !== null
            ? <MapGpx
                clickLocation={id => handleMarkerClick(id+(pageVisible-1)*10)}
                gpx={circuit.itinerary}
                itemLocations={pagesProducts[pageVisible-1].map(product => ({
                  latitude: product.latitude,
                  longitude: product.longitude,
                }))}
                selectedSlide={selectedSlide}
                userLocation={isGeolocated === true ? userLocation : undefined}
              />
            : <Map
                initCenter={[userLocation.latitude, userLocation.longitude]}
                userLocation={isGeolocated === true ? userLocation : undefined}
                selected={selectedSlide}
                clickLocation={id => handleMarkerClick(id+(pageVisible-1)*10)}
                itemLocations={pagesProducts[pageVisible-1].map(product => ({
                  latitude: product.latitude,
                  longitude: product.longitude,
                }))}
                pageVisible={pageVisible}
                productsList={pagesProducts[pageVisible-1]}
              />
          }
        </div>
      }
      {showMap === true &&
        <MobileMap
          handleShowMap={handleShowMap}
          setExternalShowMap={setExternalShowMap}
          userLocation={isGeolocated === true ? userLocation : undefined}
          selectedMarker={selectedMarker}
          activeProducts={pagesProducts[pageVisible-1]}
          handleMarkerClick={handleMarkerClick}
          selectedSlide={selectedSlide}
          handleSwipe={handleSwipe}
          pageVisible={pageVisible}
          nbProducts={activeProducts.length}
          previousPage={previousPage}
          nextPage={nextPage}
          theme={theme}
          setSelectedMarkerOnPageChange={setSelectedMarkerOnPageChange}
          gpx={circuit && circuit.itinerary}
          isCircuit={!!circuit}
          isEvent={isEvent}
        />
      }
      </div>}
      </div>
    </ResultsWrapper>
  );
};

Results.propTypes = {
  // categoryName: PropTypes.string,
  // isCatalog: PropTypes.bool,
  // page: PropTypes.string,
  // circuit: PropTypes.object,
  // desires: PropTypes.array.isRequired,
  // momentsList: PropTypes.array.isRequired,
  // defaultMoment: PropTypes.number.isRequired,
  // onSearchChange:PropTypes.func.isRequired,
  // locations: PropTypes.array.isRequired,
  // defaultLocation: PropTypes.number.isRequired,
  defaultProfile: PropTypes.number.isRequired,
  profiles: PropTypes.array.isRequired
}

export default Results;
