import React, { useContext } from 'react';
import { AccountContext } from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import ProductPhotoExample from 'ToolboxAssets/img/secret-example.png';
import { NoProductWrapper } from 'ToolboxComponents/webapp/pages/results-view/components/no-product/no-product.styles';

const NoProduct = ({ isFavorites, theme }) => {
  const [account] = useContext(AccountContext).usePath();
   return (
      <NoProductWrapper theme={theme} >
        <img className='product-photo' src={ProductPhotoExample} />
        { account && isFavorites
          ? <div className='description'>
              <p>{account.firstName},</p>
              <Text path={`page.account.noFavorites`} />
              <div className='icon-container'>
                <i className='icon adn adn-heart-outline' />
              </div>
            </div>
          : <div className='description'>
              <Text path={`page.catalog.noProduct`} />
            </div>
        }
      </NoProductWrapper>
  );
};

NoProduct.propTypes = {
  isFavorites: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

export default NoProduct;
