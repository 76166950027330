export function getNbSlidesAndWidth(profilesLength) {
  let profilesSliderWidth = 510;
  try {
    profilesSliderWidth = document.getElementsByClassName('slider-profiles')[0].offsetWidth;
  } catch (err) {
  }
 
  let nbSlides = Number((profilesSliderWidth / 110).toFixed(1));
  const gap = Number((Math.ceil(nbSlides) - nbSlides).toFixed(1));
  let newNbSlides = null;
  let newSlidesWidth = null;
  if ((profilesLength > nbSlides) && (gap !== 0.6) ) {
    if (gap > 0) {
      newNbSlides = nbSlides - 0.5 + gap;
    } else {
      newNbSlides = nbSlides + 0.5 + gap;
    }
    newSlidesWidth = Number(((profilesSliderWidth * Math.ceil(newNbSlides) / newNbSlides) / Math.ceil(newNbSlides)).toFixed(1));
    const nbProfileSlides = newNbSlides - 0.075; // permet de centrer le slide et annuler le décalage du margin
    const newProfileSlideWidth = newSlidesWidth - 15;
    return {nbProfileSlides: nbProfileSlides, newProfileSlideWidth: newProfileSlideWidth};
  } else {
    const nbProfileSlides = Number((profilesSliderWidth / 110).toFixed(1));
    const newProfileSlideWidth = 1;
    return {nbProfileSlides: nbProfileSlides, newProfileSlideWidth: newProfileSlideWidth};
  }
}
