import React, { useContext, useRef, useEffect, useState }  from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ResultsProductCard from 'ToolboxComponents/commons/cards/results-product-card/results-product-card';
import { LanguageContext } from "ToolboxUtils/web/context/context";

const ListCardsWrapper = styled.div``;

const getHighlightText = (index, hasOneHighlightProductOnly, hasNoHighlightProduct, language, isSecretHighlightOnly, maxSecretCount) => {
  if (hasNoHighlightProduct) {
    return null
  }
  if (index === 0 && !isSecretHighlightOnly) {
    return <><i className="icon adn adn-map-marker"></i>{{
      fr: <span>Le plus proche</span>,
      en: <span>The closest</span>
    }[language]}</>;
  } else if (maxSecretCount > 0 && ((index === 0 && isSecretHighlightOnly) || (index === 1 && !hasOneHighlightProductOnly && language === "fr"))) {
    return <><i className="icon adn adn-heart"></i>Le plus chuchoté</>;
  } else {
    return null;
  }
};

let previousMarkerSlide = -1;


const ListCards = ({ activeProducts, isSelectedProduct, handleHover, onClickListCard, selectedSlide, toggleFavoriteActive, pageVisible, isCluster, isEvent, targetPage, hasOneHighlightProductOnly, hasNoHighlightProduct, isMobile, selectedMarkerSlide, isSecretHighlightOnly, maxSecretCount }) => {
  const [language] = useContext(LanguageContext).usePath();
  const elementsRefs = useRef([]);

  useEffect(() => {
    if (previousMarkerSlide === selectedMarkerSlide) {
      return;
    }
    previousMarkerSlide = selectedMarkerSlide;
    if (isMobile === false) {
      const element = elementsRefs.current[selectedMarkerSlide-(pageVisible-1)*10];
      if (!element) {
        return;
      }
      const measureElement = () => {
        // 190 = header size / 32 = space to top / 352 = slider height
        const scrollY = element.getBoundingClientRect().top + window.scrollY - 190 - 32;
        window.scrollTo({ behavior: 'smooth', top: scrollY, left: 0 });
      };
  
      // Utilisation de setTimeout pour différer l'appel à getBoundingClientRect
      // après que les dimensions de l'élément aient été correctement calculées
      setTimeout(measureElement, 0);
    }
  }, [selectedMarkerSlide]);

  return (
    <ListCardsWrapper>
      <div className="list-cards">
        {activeProducts.map((product, index) => (
          <ResultsProductCard
            key={index}
            ref={el => elementsRefs.current[index] = el}
            cardId={`slide-product--${index+(pageVisible-1)*10}`}
            handleHover={() => {handleHover(index)}}
            product={product}
            toggleFavoriteActive={toggleFavoriteActive && (active => toggleFavoriteActive({active: active, productId: product.id}))}
            className={`${index === selectedSlide-(pageVisible-1)*10 ? 'selected' : ''} `}
            onClick={() => onClickListCard(index)}
            isCluster={isCluster}
            isEvent={isEvent}
            targetPage={targetPage}
            isSelectedProduct={isSelectedProduct}
            fromPage={'listing'}
            highlightText={getHighlightText(index+(pageVisible-1)*10, hasOneHighlightProductOnly, hasNoHighlightProduct, language, isSecretHighlightOnly, maxSecretCount)}
          />
        ))}
      </div>
    </ListCardsWrapper>
  );
};

ListCards.propTypes = {
  activeProducts: PropTypes.array,
  handleHover: PropTypes.func,
  toggleFavoriteActive: PropTypes.func,
  selectedSlide: PropTypes.number,
  onClickListCard: PropTypes.func,
  pageVisible: PropTypes.number.isRequired,
};

export default ListCards;
