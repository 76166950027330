import React, { useState, useEffect, useContext } from 'react';
import {ThemeContext} from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import { MomentWrapper } from './moment-product-card.styles';

const MomentSlider = props => {
  const { moment } = props;
  const theme = useContext(ThemeContext).state;
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  return (
    <MomentWrapper theme={theme} isMobile={isMobile}>
      <button type="button"
        onClick={() => props.setMomentSelected(moment.id)}
        className={'btn-search' + (moment.id === props.momentSelected ? ' active' : '') + (moment.date ? ' date' : '')}
        disabled={moment.date ? true : false}
      >
        {moment.date ?
          <React.Fragment>
            <span className='day-number'>{moment.date}</span>
            <span className='month'>{moment.month}</span>
          </React.Fragment>
          : <i className={`icon adn adn-${moment.picture}`}></i>
        }
        <span className="search-form-label">{moment.name}</span>
      </button>
    </MomentWrapper>
  );
};

MomentSlider.propTypes = {
  moment: PropTypes.object.isRequired,
  setMomentSelected: PropTypes.func.isRequired,
  momentSelected: PropTypes.number.isRequired,
};

export default MomentSlider;
