import React from 'react';
import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import { MobileMenuWrapper } from 'ToolboxComponents/webapp/pages/results-view/components/mobile-menu/mobile-menu.styles';
import { useHistory } from 'react-router-dom';


const MobileMenu = ({ isMobile, theme, activeProducts, setShowFilters, showBandeauFiltres,setShowBandeauFiltres, handleShowMap, activeFilterNumber, isOnlySecrets, hasFilters }) => {
  const history = useHistory();

  return (
    <MobileMenuWrapper isMobile={isMobile} theme={theme} hasFilters={hasFilters}>
      {/* <div code='search' className='edit-search'  onClick={() => history.goBack()}>
        <span className='adn adn-arrow-left-circle-outline icon' />
      </div>
     <Text
        path='page.results.resultsCount'
        data={{resultsCount: activeProducts.length, resultPlural: activeProducts.length > 1 ? 's' : ''}}
  />*/}
      <div className='row map' onClick={() => handleShowMap(true)}>
          <Text path='page.results.map' />
          <span className='adn adn-map icon' />
        </div>
    </MobileMenuWrapper>
  );
};

MobileMenu.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  activeProducts: PropTypes.array.isRequired,
  setShowFilters: PropTypes.func.isRequired,
  handleShowMap: PropTypes.func.isRequired,
  activeFilterNumber: PropTypes.number.isRequired,
  isOnlySecrets: PropTypes.bool.isRequired,
  hasFilters: PropTypes.bool,
};

export default MobileMenu;
